import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import { BASE_URL } from "./config/constant";
import history from "./services/history";

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            history={history}
            render={(props) => (
              <Layout>
                {route.routes ? (
                  renderRoutes(route.routes)
                ) : (
                  <Component {...props} />
                )}
              </Layout>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/login",
    component: lazy(() => import("./views/auth/signin/SignIn1")),
  },
  {
    exact: true,
    path: "/forgotpassword",
    component: lazy(() => import("./views/auth/ForgotPassword/ForgotPassword")),
  },
  {
    exact: true,
    path: "/verifyOtp",
    component: lazy(() => import("./views/auth/ForgotPassword/VerifyOtp")),
  },
  {
    exact: true,
    path: "/resetpassword",
    component: lazy(() => import("./views/auth/ForgotPassword/ResetPassword")),
  },
  {
    exact: true,
    path: "/auth/signup-1",
    component: lazy(() => import("./views/auth/signup/SignUp1")),
  },
  {
    path: "*",
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: "/dashboard",
        component: lazy(() => import("./views/dashboard/DashDefault")),
      },
      {
        exact: true,
        path: "/users",
        component: lazy(() => import("./views/users/Users")),
      },
      {
        exact: true,
        path: "/banners",
        component: lazy(() => import("./views/banners/Banners")),
      },
      {
        exact: true,
        path: "/retailer",
        component: lazy(() => import("./views/Retailer/Retailer")),
      },

      {
        exact: true,
        path: "/supplier",
        component: lazy(() => import("./views/Supplier/Supplier")),
      },
      {
        exact: true,
        path: "/plans",
        component: lazy(() => import("./views/plans/Plans")),
      },
      {
        exact: true,
        path: "/department",
        component: lazy(() => import("./views/department/Department")),
      },
      {
        exact: true,
        path: "/category",
        component: lazy(() => import("./views/category/Category")),
      },
      {
        exact: true,
        path: "/static-pages",
        component: lazy(() => import("./views/staticPage/staticPage")),
      },
      {
        exact: true,
        path: "/basic/button",
        component: lazy(() => import("./views/ui-elements/basic/BasicButton")),
      },
      {
        exact: true,
        path: "/basic/badges",
        component: lazy(() => import("./views/ui-elements/basic/BasicBadges")),
      },
      {
        exact: true,
        path: "/basic/breadcrumb",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicBreadcrumb")
        ),
      },
      {
        exact: true,
        path: "/basic/pagination",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicPagination")
        ),
      },
      {
        exact: true,
        path: "/basic/collapse",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicCollapse")
        ),
      },
      {
        exact: true,
        path: "/basic/tabs-pills",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicTabsPills")
        ),
      },
      {
        exact: true,
        path: "/basic/typography",
        component: lazy(() =>
          import("./views/ui-elements/basic/BasicTypography")
        ),
      },
      {
        exact: true,
        path: "/forms/form-basic",
        component: lazy(() => import("./views/forms/FormsElements")),
      },
      {
        exact: true,
        path: "/tables/bootstrap",
        component: lazy(() => import("./views/tables/BootstrapTable")),
      },
      {
        exact: true,
        path: "/charts/nvd3",
        component: lazy(() => import("./views/charts/nvd3-chart")),
      },
      {
        exact: true,
        path: "/maps/google-map",
        component: lazy(() => import("./views/maps/GoogleMaps")),
      },
      {
        path: "*",
        exact: true,
        component: () => <Redirect to={BASE_URL} />,
      },
    ],
  },
];

export default routes;
